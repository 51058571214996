import { includes } from 'lodash';

const ROOM_DESIGNATIONS = ['common', 'bedroom', 'livingroom'] as const;

export type RoomDesignation = (typeof ROOM_DESIGNATIONS)[number];

type RoomDesignationOption = {
  value: RoomDesignation;
  label: string;
};

export const ROOM_DESIGNATION_OPTIONS = {
  bedroom: {
    value: 'bedroom',
    label: 'Bedroom',
  },
  common: {
    value: 'common',
    label: 'Common area',
  },
  livingroom: {
    value: 'livingroom',
    label: 'Living room',
  },
} as const satisfies Record<RoomDesignation, RoomDesignationOption>;

export const isValidRoomDesignation = (
  value: string,
): value is RoomDesignation => includes(ROOM_DESIGNATIONS, value);
